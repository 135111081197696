<template>
  <default-layout>
    <div class="register-phone default-layout-main text-center mb-7">
      <h2 class="text-xl">Yêu cầu đã được ghi nhận</h2>
      <p class="mt-4 mb-6">Để hoàn thiện yêu cầu, mời bạn tạo tài khoản</p>
      <base-input
        v-model:modelValue="fullName"
        typeInput="text"
        placeholder="Họ và tên"
        text-center
        class="text-xl"
      />
      <base-text-error :errorMessages="errorName" />
      <base-input
        v-model:modelValue="phone"
        typeInput="text"
        mode="numeric"
        placeholder="Số điện thoại của bạn"
        text-center
        length="10"
        :spacing="!!phone"
        class="text-xl mt-1"
      />
      <base-text-error :errorMessages="errorPhone" />
      <div class="relative">
        <base-input
          v-model:modelValue="password"
          :type-input="showPass ? 'text' : 'password'"
          placeholder="Mật khẩu"
          text-center
          class="text-xl mt-1"
        />
        <div class="absolute right-0 top-0">
          <icon-eye-off v-if="showPass" @click="() => (showPass = false)" />
          <icon-eye v-else @click="() => (showPass = true)" />
        </div>
      </div>
      <base-text-error :errorMessages="errorPass" />
      <base-input
        typeInput="text"
        placeholder="Email của bạn"
        text-center
        v-model:modelValue="email"
        class="text-xl mt-1"
      />
      <base-text-error :errorMessages="errorEmail" />
      <p class="rules mt-6">
        <i>
          Bằng việc nhấn <span>Tiếp tục</span>, bạn đã đồng ý <br />
          <a
            href="https://interloan.vn/static/documents/BoDieukhoanvaDieukien.pdf"
            target="_blank"
            ><u class="tertiary-color text-tertiary"
              >Điều khoản và chính sách</u
            ></a
          >
          của <b class="text-primary">Interloan</b>
        </i>
      </p>
      <base-button
        :loading="loading"
        @click="handleSubmit"
        class="w-full border-16 bg-tertiary text-white mt-7 py-6"
        >Tiếp tục
      </base-button>
      <p class="mt-4">
        Đã có tài khoản?
        <router-link
          :to="{ name: 'login' }"
          class="text-tertiary"
          style="text-decoration: underline"
          >Đăng nhập!
        </router-link>
      </p>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconEye from '@/components/Icon/IconEye.vue';
import IconEyeOff from '@/components/Icon/IconEyeOff.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import constRouter from '@/constants/constRouter';
import { signUp } from '@/apis/ApiAuth';
import { mapMutations } from 'vuex';
import { required } from '@/helpers/validate';
import { ref } from 'vue';
import { setAuthToken } from '@/helpers/localStorage';
import { validateEmail } from '@/helpers';
export default defineComponent({
  components: {
    BaseButton,
    BaseInput,
    DefaultLayout,
    BaseTextError,
    IconEye,
    IconEyeOff,
  },
  name: 'RegisterPhone',
  setup() {
    const phone = ref('');
    const password = ref('');
    const fullName = ref('');
    const showPass = ref('');
    const email = ref('');
    const errorPhone = ref('');
    const errorPass = ref('');
    const errorName = ref('');
    const errorEmail = ref('');
    const loading = ref(false);
    return {
      phone,
      password,
      fullName,
      showPass,
      email,
      errorPhone,
      errorPass,
      errorName,
      errorEmail,
      loading,
    };
  },

  methods: {
    ...mapMutations({
      setPhone: 'setPhone',
      setEmail: 'setEmail',
      setName: 'setName',
      setPassword: 'setPassword',
    }),
    // openModal() {
    //   const modal = document.querySelector('.main-modal');
    //   modal.classList.remove('fadeOut');
    //   modal.classList.add('fadeIn');
    //   modal.style.display = 'flex';
    // },
    validation() {
      this.errorPhone = required('Phone', this.phone);
      this.errorPass = required('Pass', this.password);
      this.errorName = required('Name', this.fullName);
      this.errorEmail = required('Email', this.email);
      if (!this.phone || !this.password || !this.fullName || !this.email) {
        return false;
      }
      if (!validateEmail(this.email)) {
        this.errorEmail = 'Email không hợp lệ';
        return false;
      }
      if (this.phone) {
        const pattern = /(([1-9]{1})+([0-9]{8})\b)/;
        if (!this.phone.match(pattern)) {
          this.errorPhone = 'Số điện thoại không hợp lệ';
          return false;
        }
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        this.setPhone(this.phone);
        this.setEmail(this.email);
        this.setName(this.fullName);
        this.setPassword(this.password);
        this.loading = true;
        signUp({
          fullName: this.fullName,
          phone: this.phone,
          password: this.password,
        })
          .then(res => {
            this.loading = false;
            if (res.data?.statusCode === 200) {
              setAuthToken(res.data?.data.token);
              this.$router.push({
                name: constRouter.CONFIRM_OTP.name,
              });
            } else {
              this.errorPhone = res.data?.message;
            }
          })
          .catch(err => {
            this.loading = false;
            this.errorPhone = err.data.message;
            console.log(err);
            // const message = handleErrAPI(err);
            // if (message[0]) {
            //   this.errorPhone = message[1];
            // }

            // if (err.data?.message) {
            //   this.errorPhone = err.data.message;
            // } else {
            //   this.errorPhone = 'Số điện thoại không hợp lệ';
            // }
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.register-phone {
  margin-top: 28px;
}
</style>
